.tabContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    color: #000000DE;
}
.fullHeight {
    height: 100%;
}

.switcher {
    display: flex;
    justify-content: space-around;
    height: 50px;
}

.radio {
    justify-content: center !important;
}

.radio svg {
    color: #8BD1E8
}

.manualInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.divider {
    flex:1
}

.input {
    width: 100%;
    border: 2px solid #8BD1E8;
    margin-top: 20px;

    input {
        padding: 5px 7px;
        font-size: 18px;
        font-family: 'Roboto', serif;

        &::placeholder {
            font-weight: 500;
            font-style: normal;
            color: #797979;
            opacity: 1;
        }

    }
    &:before {
        content: "";
        border-bottom: none!important;
    }
    &:after {
        content: "";
        border-bottom: none!important;
    }
}
.buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
